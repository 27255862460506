(function ($) {
	if ($(".js-modal-video").length) {
		$(".js-modal-video").modalVideo({
			channel: "youtube",
			youtube: {
				controls: 1, // コントロール表示（0は非表示）
			},
		});
	}

	$(window).on('load', function () {
		$('.headerNavi').removeClass('preload');
	});
})(jQuery);
